<script>
import TablePage from '../../../../../components/table_page';
import Form from './form.vue';

export default {
  name: 'table-component',
  extends: TablePage,
  components: { Form },
  data() {
    return {
      requestUrl: '/sfa/SfaIndexController/list',
      formConfig: {},
    };
  },
  methods: {
    modalClick({ val, row }) {
      console.log('table点击=========按钮', val);
      if (val.code === 'add') this.btnAdd();
      if (val.code === 'edit') this.btnEdit(row);
      if (val.code === 'view') this.btnCheck(row);
    },
    /** @desc 点击cell */
    cellClick({ row, column }) {
      if (column.property === 'indexCode_1') {
        this.formName = 'Form';
        this.formConfig = {
          row,
          type: 'view',
          disabled: true,
        };
        this.modalConfig.title = '查看详情';
        this.openFull();
      }
    },
    /** @desc 新增 */
    btnAdd() {
      this.formName = 'Form';
      this.formConfig.row = {};
      this.formConfig.disabled = false;
      this.modalConfig.title = '新增';
      this.openFull();
    },
    /** @desc 编辑 */
    btnEdit(row) {
      this.formName = 'Form';
      this.formConfig.row = { ...row };
      this.formConfig.disabled = false;
      this.modalConfig.title = '编辑';
      this.openFull();
    },
  },
  created() {
    this.getConfigList('sfaCenter_performanceMange_indexSet_list');
  },

};
</script>
