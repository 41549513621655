<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      buttons: {
        submit: true,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  methods: {
    /** @override 覆盖提交事件 */
    submit() {
      const formData = this.getFormData();
      if (!formData) return;
      // 重新赋值参数
      const requestParam = {
        indexCode: formData.indexCode,
        indexName: formData.indexName,
      };
      // 区分是编辑还是新增
      let requestUrl = '/sfa/SfaIndexController/save';
      let message = '添加成功';
      if (this.formConfig.row && this.formConfig.row.id) {
        requestUrl = '/sfa/SfaIndexController/update';
        requestParam.id = this.formConfig.row.id;
        message = '更新成功';
      }
      request.post(requestUrl, requestParam).then((res) => {
        if (res.code === 200) {
          this.$message.success(message);
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message(res.message);
        }
      });
    },
  },
  /** @desc 重新赋值rule,反正感觉怪怪的有问题 */
  async created() {
    const rule = await this.getFormRule('sfaCenter_performanceManage_indexSet_form');
    this.buttons.submit = !this.formConfig.disabled;
    this.rule = rule;
    this.reload(this.rule);
    // 编辑
    if (this.formConfig.row && this.formConfig.row.id) {
      this.setValue({
        indexCode: this.formConfig.row.indexCode,
        indexName: this.formConfig.row.indexName,
      });
    }
  },
};
</script>
